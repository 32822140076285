<template>
  <div class="problemListDetails">
    <div class="box">
      <div class="content-left" v-loading="loading">
        <div class="course-title">
          <div style="display: flex; justify-content: space-between">
            <div style="width: 80%">
              <div class="name" v-html="detailList.title"></div>
            </div>
            <div class="edit">
              <div
                class="feedback"
                @click="
                  handleCommand(
                    'collect',
                    2,
                    detailList.id,
                    'detailList',
                    !detailList.is_collect
                  )
                "
              >
                <i
                  class="el-icon-star-off icon"
                  v-if="!detailList.is_collect"
                ></i>
                <i
                  class="el-icon-star-on icon"
                  v-if="detailList.is_collect"
                  style="color: #f56c6c"
                ></i>
                收藏
              </div>
              <div
                class="feedback"
                @click="
                  handleCommand('feedback', detailList.title, '题单', {
                    id: detailList.id,
                  })
                "
              >
                <i class="el-icon-edit-outline"></i>反馈
              </div>
              <div class="feedback sameStyle" @click="joinList" style="min-width: 110px">
                <!-- v-if="(userInfo.id==detailList.user_id)||log_id" -->
                <i class="el-icon-view" style="font-size: 20px"> </i>
                查看答题详情
              </div>
              <!-- <div
                class="feedback"
                @click="editProblemList"
                v-if="userInfo.id === detailList.user_id"
              >
                编辑题单
              </div> -->
            </div>
          </div>
          <span style="font-size: 12px"
            >题目数：{{ detailList.questions.length }}</span
          >
          <div style="margin: 10px 0; display: flex; font-size: 12px;align-items: center;">
            涵盖题目知识点：
            <div
              class="konwsItem"
              v-for="(v, i) in detailList.know_format"
              :key="i"
            >
              {{ v.title }}
            </div>
          </div>
          <div class="detailed">
            <el-tooltip
              class="item"
              effect="dark"
              content="常用的练习模式，非编程题可以直接看到答案，编程题可以单独提交代码查看结果"
              placement="top"
            >
              <el-button
                icon="el-icon-edit"
                type="primary"
                size="small"
                @click="goExam(1,0)"
                :disabled="(detailList.questions_list || []).length === 0"
                >自主练习</el-button
              >
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="题单将作为试卷整体提交，提交后统一显示每道题是否正确"
              placement="top"
            >
              <el-button
                icon="el-icon-trophy"
                type="primary"
                size="small"
                @click="goExam(2,1)"
                :disabled="(detailList.questions_list || []).length === 0"
                >模拟考试</el-button
              >
            </el-tooltip>

            <!-- <span>收藏数：{{detailList.collect_num}}</span> -->
          </div>
        </div>
        <el-table
          :data="questionsData"
          tooltip-effect="dark"
          style="width: 100%"
          :row-style="{ height: '0' }"
          :cell-style="{ padding: '6px' }"
          :header-cell-style="{
            height: '50',
            padding: '4px',
            background: '#F9F9F9',
          }"
        >
          <el-table-column label="题号" width="140" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.qid }}</span>
            </template>
          </el-table-column>
          <el-table-column label="题型" width="140" align="center">
            <template slot-scope="scope">{{ scope.row.type_str }}</template>
          </el-table-column>
          <el-table-column label="题目" show-overflow-tooltip="" align="center">
            <template slot-scope="scope">
              <span v-html="scope.row.title"></span></template>
          </el-table-column>
          <el-table-column label="来源" width="140" align="center">
            <template slot-scope="scope">{{
              scope.row.fromStr ? scope.row.fromStr : "--"
            }}</template>
          </el-table-column>
          <el-table-column label="难度" width="140" align="center">
            <template slot-scope="scope">{{
              scope.row.diffStr ? scope.row.diffStr : "--"
            }}</template>
          </el-table-column>
          <el-table-column
            label="知识点"
            show-overflow-tooltip
            align="center"
          >
            <template slot-scope="scope">
              <span class="konwsItem" v-for="v,i in scope.row.know_list" :key="i">
                {{ v?v:'-' }}
              </span>
            </template>
          </el-table-column>
        </el-table>

        <!-- <el-tabs type="border-card">
          <el-tab-pane label="题单介绍">
            <div class="brief" v-code-html="detailList.description"></div>
          </el-tab-pane>
          <el-tab-pane label="查看题目">
            <div class="brief">简介</div>
          </el-tab-pane>
        </el-tabs> -->
      </div>
    </div>
    <Payment
      ref="Payment"
      :order_num="order_num"
      :mobile="mobile"
      :price="price"
    ></Payment>
  </div>
</template>

<script>
import Payment from "@/components/Payment/Payment.vue";
// 题目列表和知识点标签
import TopicList from "@/components/TopicList/TopicList.vue";
import { detailList } from "@/api/problemList.js";
import HotRecommend from "@/views/api/com/hotRecommend";
export default {
  props: ["list_id"],
  components: {
    HotRecommend,
    TopicList,
    Payment,
  },
  data() {
    return {
      order_num: "",
      mobile: "",
      detailList: {
        questions: [],
      },
      questionsData: [], //题单下题目表格
      loading: false,
      log_id: "",
      is_buy: 0,
      price: "",
    };
  },
  methods: {
    routeUpdate() {
      let query = this.$route.query;
      this.is_buy = query.is_buy;
      // this.log_id = localStorage.getItem('logId')
      //老师查看学生答题状态
      if (query.log_id) {
        this.log_id = query.log_id;
      }
      this.loading = true;
      this.getDetailList();
    },
    editProblemList() {
      if (this.userInfo.type + "" === "2") {
        this.$handleRoute({ list_id: this.list_id }, "teachingQuestListEdit");
      } else {
        this.$store.state.topicType = {
          name: "编辑题单",
          detail: this.detailList,
        };
        this.$router.push({
          path: "/problemListOperate",
          query: { id: this.list_id },
        });
      }
    },
    //查看答题详情
    joinList() {
      if (this.log_id) {
        // 学生查看单个题单详情
        // if (this.userInfo.type==1) {
        // this.$router.push({ path: '/problemList/tiDanRecord', query: {id: this.list_id} })
        this.$router.push({
          path: "/problemList/questAnswer",
          query: {
            id: this.list_id,
            type: "题单",
            log_id: this.log_id,
            is_log_answer: 1,
            noTime: 1,
          },
        });
        return;
      }
      // 老师查看表格
      this.$router.push({
        path: "/problemList/overDetails",
        query: { id: this.list_id },
      });
    },
    getDetailList() {
      detailList({ id: this.list_id })
        .then((res) => {
          res.data.description = res.data.description.replace(
            /<img/g,
            "<img style='max-width:100%;height:auto;'"
          );
          console.log(res.data, "res`~~~~~~~~~");
          this.detailList = res.data;
          if (res.data.questions && res.data.questions.length > 0) {
            this.questionsData = res.data.questions;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    topicClick() {},
    goExam(noTime,duration) {
      // this.$router.push({ path: '/problemList/questAnswer', query: { id: this.list_id, noTime,answer_type:noTime==1?1:0 } })
      this.$handleRoute(
        { id: this.list_id, noTime, answer_type: noTime == 1 ? 1 : 0,duration:duration },
        "/problemList/questAnswer",
        "_blank"
      );
    },
  },
};
</script>

<style lang="less" scoped>
.problemListDetails {
  .box {
    display: flex;
    justify-content: space-between;

    .content-left {
      width: 100%;
      .brief {
        padding: 2rem 1rem;
      }
      .course-title {
        margin-bottom: 20px;
        padding: 1.5rem;
        background: #ffffff;
        border-radius: 10px;
        border: 1px solid #dbdbdb;
        // display: flex;
        // justify-content: space-between;
        // align-items: center;
        .edit {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .feedback {
            display: flex;
            align-items: center;
            font-size: 14px;
            cursor: pointer;
            margin-left: 1rem;
            min-width: 60px;
          }
          .el-icon-edit-outline,
          .el-icon-star-off,
          .el-icon-star-on,
          .el-icon-circle-plus-outline {
            font-size: 20px;
            margin-right: 0.2rem;
          }
        }
        .name {
          font-weight: bold;
          word-break: break-all;
          font-size: 21px;
          color: #0a0a0a;
          line-height: 29px;
          margin-bottom: 1rem;
        }
        .detailed {
          font-size: 14px;
          color: #0a0a0a;
          line-height: 20px;
          > span {
            margin-right: 1.5rem;
          }
          .operation {
            margin-right: 1.5rem;
          }
          .operation[data-disabled="true"] {
            color: #c3c3c3;
            pointer-events: none;
          }
        }
      }
      ::v-deep {
        .el-tabs--border-card {
          border-radius: 10px;
        }
        .el-tabs__item {
          font-size: 16px;
          height: 50px;
          width: 130px;
          line-height: 50px;
          text-align: center;
        }
        .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
          color: #00957e;
        }
        .el-tabs__item:hover {
          color: #00957e;
        }
      }
    }
    .content-right {
      width: 27%;
    }
  }
}
.konwsItem {
  margin-right: 4px;
  padding: 2px 6px;
  box-sizing: border-box;
  background: #f5f5f5;
  border-radius: 4px;
  border: 1px solid #dddddd;
  font-size: 12px;
  color: #666666;
  text-align: center;
}
.sameStyle{
  color: #00957e !important;
    font-size: 16px!important;
    font-weight: 600!important;
    line-height: 38px!important;
}
</style>