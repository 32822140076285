var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"problemListDetails"},[_c('div',{staticClass:"box"},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"content-left"},[_c('div',{staticClass:"course-title"},[_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('div',{staticStyle:{"width":"80%"}},[_c('div',{staticClass:"name",domProps:{"innerHTML":_vm._s(_vm.detailList.title)}})]),_c('div',{staticClass:"edit"},[_c('div',{staticClass:"feedback",on:{"click":function($event){return _vm.handleCommand(
                  'collect',
                  2,
                  _vm.detailList.id,
                  'detailList',
                  !_vm.detailList.is_collect
                )}}},[(!_vm.detailList.is_collect)?_c('i',{staticClass:"el-icon-star-off icon"}):_vm._e(),(_vm.detailList.is_collect)?_c('i',{staticClass:"el-icon-star-on icon",staticStyle:{"color":"#f56c6c"}}):_vm._e(),_vm._v(" 收藏 ")]),_c('div',{staticClass:"feedback",on:{"click":function($event){return _vm.handleCommand('feedback', _vm.detailList.title, '题单', {
                  id: _vm.detailList.id,
                })}}},[_c('i',{staticClass:"el-icon-edit-outline"}),_vm._v("反馈 ")]),_c('div',{staticClass:"feedback sameStyle",staticStyle:{"min-width":"110px"},on:{"click":_vm.joinList}},[_c('i',{staticClass:"el-icon-view",staticStyle:{"font-size":"20px"}}),_vm._v(" 查看答题详情 ")])])]),_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v("题目数："+_vm._s(_vm.detailList.questions.length))]),_c('div',{staticStyle:{"margin":"10px 0","display":"flex","font-size":"12px","align-items":"center"}},[_vm._v(" 涵盖题目知识点： "),_vm._l((_vm.detailList.know_format),function(v,i){return _c('div',{key:i,staticClass:"konwsItem"},[_vm._v(" "+_vm._s(v.title)+" ")])})],2),_c('div',{staticClass:"detailed"},[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"常用的练习模式，非编程题可以直接看到答案，编程题可以单独提交代码查看结果","placement":"top"}},[_c('el-button',{attrs:{"icon":"el-icon-edit","type":"primary","size":"small","disabled":(_vm.detailList.questions_list || []).length === 0},on:{"click":function($event){return _vm.goExam(1,0)}}},[_vm._v("自主练习")])],1),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"题单将作为试卷整体提交，提交后统一显示每道题是否正确","placement":"top"}},[_c('el-button',{attrs:{"icon":"el-icon-trophy","type":"primary","size":"small","disabled":(_vm.detailList.questions_list || []).length === 0},on:{"click":function($event){return _vm.goExam(2,1)}}},[_vm._v("模拟考试")])],1)],1)]),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.questionsData,"tooltip-effect":"dark","row-style":{ height: '0' },"cell-style":{ padding: '6px' },"header-cell-style":{
          height: '50',
          padding: '4px',
          background: '#F9F9F9',
        }}},[_c('el-table-column',{attrs:{"label":"题号","width":"140","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.row.qid))])]}}])}),_c('el-table-column',{attrs:{"label":"题型","width":"140","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(_vm._s(scope.row.type_str))]}}])}),_c('el-table-column',{attrs:{"label":"题目","show-overflow-tooltip":"","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{domProps:{"innerHTML":_vm._s(scope.row.title)}})]}}])}),_c('el-table-column',{attrs:{"label":"来源","width":"140","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(_vm._s(scope.row.fromStr ? scope.row.fromStr : "--"))]}}])}),_c('el-table-column',{attrs:{"label":"难度","width":"140","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(_vm._s(scope.row.diffStr ? scope.row.diffStr : "--"))]}}])}),_c('el-table-column',{attrs:{"label":"知识点","show-overflow-tooltip":"","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return _vm._l((scope.row.know_list),function(v,i){return _c('span',{key:i,staticClass:"konwsItem"},[_vm._v(" "+_vm._s(v?v:'-')+" ")])})}}])})],1)],1)]),_c('Payment',{ref:"Payment",attrs:{"order_num":_vm.order_num,"mobile":_vm.mobile,"price":_vm.price}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }